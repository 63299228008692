import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="cotainer services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icons" />
              <p>User research and persona development</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Wireframing and prototyping</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Usability testing and user feedback</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Information architecture and content strategy</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Front-end development (HTML/CSS/JavaScript)</p>
            </li>
          </ul>
        </article>

        {/* End of UI/UX  */}

        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icons" />
              <p>Custom Website Development</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Mobile Responsive Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Search Engine Optimisation (SEO)</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>E-commerce website development</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>WordPress website development using custom themes and plugins</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Ongoing website maintenance and support</p>
            </li>
          </ul>
        </article>

        {/* End of Web Development  */}

        <article className="service">
          <div className="service__head">
            <h3>Content Creation</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icons" />
              <p>Blog post writing</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Email newsletter creation</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Content strategy development</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Blog post writing</p>
            </li>
            <li>
              <BiCheck className="service__list-icons" />
              <p>Website copywriting</p>
            </li>
          </ul>
        </article>

        {/* End of Content Creation */}
      </div>
    </section>
  )
}

export default Services