import {React, useRef, useState} from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {sendForm} from '@emailjs/browser'
import { Snackbar, Alert } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'




const Contact = () => {

  const form = useRef(); 
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    sendForm('service_8i2cg0c', 'template_qu9kvo7', form.current, 'TFckf1CQg5TqHQMWI')
      .then((result) => {
          setOpen(true)
          e.target.reset();
          console.log(result.text);
      }, (error) => {
          setErrorMsg(true)
          console.log(error.text);
      });
  }

  function handleClose() {
    setOpen(false)
  }

  function handleErrorClose() {
    setErrorMsg(false)
  }
 
  
  
  return (
    
    <section id="contact">
      <h5>Get in Touch</h5>
      <h2>Contact me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>ubhuwan@gmail.com</h5>
            <a href="mailto:ubhuwan@gmail.com" target={'_blank'}>Send a message</a>
          </article>
        </div>

      {/* End of Contact Options */}
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name='name' placeholder='Full Name' required />
        <input type="email" name='email' placeholder='Email' required />
        <textarea name="message" id="" cols="30" rows="7" required></textarea>
        <button type="submit" className='btn btn-primary'>Send Message</button>
        

        <Snackbar          
          autoHideDuration={3000}        
          open={open}                   
          onClose={handleClose}                   
          anchorOrigin = {{
            vertical:'top',
            horizontal:'right'
          }}        
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Email successfully sent!
          </Alert>

        </Snackbar>

          {/* error message alert */}

        <Snackbar          
          autoHideDuration={3000}        
          open={errorMsg}                   
          onClose={handleErrorClose}
          severity="error"            
          anchorOrigin = {{
            vertical:'top',
            horizontal:'right'
          }}        
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Email successfully sent!
          </Alert>

        </Snackbar>
      </form>

      </div>
    </section>
  )
}

export default Contact