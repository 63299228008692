import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/yatrinepal.png'
import IMG2 from '../../assets/himalayan.png'
import IMG3 from '../../assets/swans.png'



const data = [
  {
    id : 1,
    image: IMG1,
    title: "Yatri Nepal",
    demo: "https://yatrinepal.com/"
  },
  {
    id : 2,
    image: IMG2,
    title: "Save Himalayan Cargo ",
    demo: "http://savehimalayancargo.com/"
  },
  {
    id : 3,
    image: IMG3,
    title: "Swans Transportation",
    demo: "https://swanstech.azurewebsites.net/login"
  }

]

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">

        {
          data.map(({id, image, title, demo}) => {
            return(
              <article key={id} className="portfolio__item">
               <div className="portfolio__item-image">
                 <img src={image} alt={title} />            
               </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href="#" className='btn'>GitHub</a>
                  <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                </div>          
              </article>  
            )
          })
        }
          
      </div>
    </section>
  )
}

export default Portfolio