import React from 'react'
import './footer.css'
import {AiOutlineLinkedin} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Me</a>

      <ul className='permalinks'>
        <li><a href="#">Ubhuwan</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">        
        <a href="https://www.linkedin.com/in/bhuwan-uprety-732088192/" target={'_blank'}><AiOutlineLinkedin className='footer__socials-icon'/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Bhuwan Uprety. All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer