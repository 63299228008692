import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    index : 1,
    avatar : AVTR1,
    name : "Loading ...",
    review : " Testimonials will be added shortly !!!"
  }
  // ,
  // {
  //   index : 2,
  //   avatar : AVTR1,
  //   name : "Fake Reviewer",
  //   review : "Lorem ipsum dumb comb honey mango buffalo trees awesome !!!"
  // },
  // {
  //   index : 3,
  //   avatar : AVTR1,
  //   name : "Genuine Reviewer",
  //   review : "Lorem ipsum dumb comb honey mango buffalo trees awesome !!!"
  // }
]

const Testimonial = () => {
  return (
    <section id="testimonials">
      <h5>Client Reviews</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{clickable:true}} >

        {data.map( ({avatar, name, review}, index) => {
          return (
            <SwiperSlide id={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt="Avatar I" />
              </div>
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review} </small>
          </SwiperSlide>
          )
      })}
      </Swiper>

    </section>
  )
}

export default Testimonial